import "./index.scss";
import { Table, Pagination, message } from "antd";
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import MyEmpty from "../Empty";

import projectApi from "../../apis/project";
import LoadingSvg from "../../assets/loading.svg";
import { ReactSVG } from "react-svg";

const DataTable = (props, ref) => {
  const {
    defaultData,
    tableName,
    changePagination,
    activeKey,
    changeOrders,
    flag,
    selectedRowKey,
    deleteItem, // 删除
    layerList,
    tableLoading,
    onRowClick,
  } = props;

  const refTable = useRef(null);

  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);

  const [selectedRowList, setSelectedRowList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [childList, setChidlist] = useState({}); // 子主题域对应表单数据项
  const [childInfo, setChidInfo] = useState({}); // 子主题域默认值
  const [childLoding, setChidLoding] = useState(true); // 子主题域表格加载状态

  const data = defaultData?.items;
  useEffect(() => {
    if (selectedRowKey) setSelectedRowKeys([]);
  }, [selectedRowKey]);
  // 处理行点击事件
  const handleRowClick = (record) => {
    onRowClick(record); // 将选中的行数据传递给父组件
  };
  // 子主题域配置
  const expandedRowRender = (item) => {
    let columns = [
      {
        title: "主题域",
        dataIndex: "sub_name",
        sorter: true,
        width: "110px",
      },
      {
        title: "数据分层",
        dataIndex: "data_layer_name",
        sorter: true,
        width: "100px",
      },
      {
        title: "英文缩写",
        dataIndex: "sub_eng_abbr",
        sorter: true,
        width: "100px",
      },
      {
        title: "描述",
        dataIndex: "sub_desc",
        width: "180px",
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={childList[item.sub_id]}
        bordered={false}
        pagination={false}
        showHeader={false}
        rowKey={(record) => record?.sub_id}
        locale={{
          emptyText: <MyEmpty></MyEmpty>,
        }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record), // 绑定点击事件
        })}
      />
    );
  };

  useEffect(() => {
    if (tableName === "SubUnpublished") {
      setColumns([
        {
          title: "主题域",
          dataIndex: "sub_name",
          sorter: true,
          width: "110px",
        },
        {
          title: "数据分层",
          dataIndex: "data_layer_name",
          width: "100px",
          sorter: true,
        },
        {
          title: "英文缩写",
          dataIndex: "sub_eng_abbr",
          width: "100px",
          sorter: true,
        },
        {
          title: "描述",
          dataIndex: "sub_desc",
          width: "180px",
        },
      ]);
    }

    setTotal(defaultData?.total);
    setPageNumber(defaultData?.page);
    setPageSize(defaultData?.size);
    if (flag) {
      setColumns((item) =>
        item.filter((data) => data.title !== "操作" && data.title !== "状态")
      );
    }
  }, [defaultData]);

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize, activeKey);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  // 获取子主题域
  const getSubStandardList = (expanded, record) => {
    setChidInfo(record);
    if (expanded) {
      setChidLoding(true);
      getChild(record);
    }
    if (expanded === false) {
      setChidlist((item) => {
        let newObj = {};
        for (let key in item) {
          if (key !== record.sub_id) {
            newObj[key] = item[key];
          }
        }
        return newObj;
      });
    }
  };

  // 获取子主题域请求
  const getChild = (record, flag) => {
    if (activeKey === "1") {
      projectApi
        .getSubStandardList(record.sub_id, {
          publish_type: "UN_PUBLISHED",
          data_layer_name: record?.data_layer_name,
          page: 1,
          size: 100,
        })
        .then((res) => {
          if (res.code === 200) {
            setChidlist({ ...childList, [record.sub_id]: res.data.items });
            setChidLoding(false);
            if (flag) {
              deleteItem(
                record,
                res.data.items.map((item) => item.sub_id)
              );
            }
          } else {
            setChidlist({});
            message.error(res.message);
            setChidLoding(false);
          }
        });
    }
    if (activeKey === "2") {
      projectApi
        .getSubStandardList(record.sub_id, {
          publish_type: "PUBLISHED",
          data_layer_name: record?.data_layer_name,
          page: 1,
          size: 100,
        })
        .then((res) => {
          if (res.code === 200) {
            setChidlist({ ...childList, [record.sub_id]: res.data.items });
            setChidLoding(false);
          } else {
            setChidlist([]);
            message.error(res.message);
            setChidLoding(false);
          }
        });
    }
  };

  // 批量删除选项
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowList(selectedRows);
  };
  // 表格第一项多选
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.chg_type_code !== "NEW" && record.ver_no !== null,
      name: record.chg_type_code,
    }),
  };

  // 暴露表格给父组件
  useImperativeHandle(ref, () => ({
    selectedRowList,
    getChild, // 更新获取子主题域
    childInfo, // 更新子主题域需要的数据
  }));

  // 改变表格排序
  const tableChange = (pagination, filters, sorter, extra) => {
    if (sorter.order !== undefined) {
      changeOrders(sorter);
    }
  };

  return (
    <div className="metaDataTable">
      <div className="tableContent" ref={refTable}>
        {layerList?.length === 0 && tableName === "SubUnpublished" ? (
          <Table
            className="myFormTable"
            pagination={false}
            columns={columns}
            dataSource={[]}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
          />
        ) : (
          <Table
            expandable={
              tableName === "SubUnpublished"
                ? {
                    expandedRowRender,
                    onExpand: getSubStandardList,
                  }
                : null
            }
            className="myFormTable"
            loading={
              tableLoading
                ? {
                    indicator: (
                      <div style={{ background: "#fff" }}>
                        <ReactSVG
                          src={LoadingSvg}
                          className="anticon-spin anticon myLoadingIcon"
                        />
                      </div>
                    ),
                  }
                : false
            }
            pagination={false}
            columns={columns}
            dataSource={data}
            // rowKey={(record, id) => id}
            rowKey={(record, id) => {
              if (tableName === "SubUnpublished") {
                return record?.sub_id;
              }
            }}
            onChange={tableChange}
            scroll={{
              y: "calc(100vh - 370px)",
            }}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record), // 绑定点击事件
            })}
          />
        )}

        {total >= 1 && !tableLoading ? (
          <div className="paginationBox">
            {/* showQuickJumper */}
            <Pagination
              className="myPagination"
              current={pageNumber}
              total={total}
              pageSize={pageSize}
              pageSizeOptions={[10, 20, 50, 100]}
              onChange={onChange}
              showTotal={(total) => `共 ${total} 条`}
              showSizeChanger={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default forwardRef(DataTable);
